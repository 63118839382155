.site {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.site-content {
    flex-grow: 1;
    padding-top: 60px;
}
