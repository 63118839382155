.icon:before {
  display: inline-block;
  font: normal normal normal 24px/1 "Alto";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-chevron-left:before {
  content: "\e929";
}
.icon-chevron-right:before {
  content: "\e92a";
}
.icon-arrow-left:before {
  content: "\e92b";
}
.icon-arrow-right:before {
  content: "\e92c";
}
.icon-map-marker:before {
  content: "\e92d";
}
.icon-menu-down:before {
  content: "\e92e";
}
.icon-menu-up:before {
  content: "\e900";
}
.icon-window-close:before {
  content: "\e92f";
}
.icon-white-balance-sunny:before {
  content: "\e930";
}
.icon-brightness-2:before {
  content: "\e931";
}
.icon-play:before {
  content: "\e932";
}
.icon-bookmark:before {
  content: "\e933";
}
.icon-behance:before {
  content: "\e934";
}
.icon-dribbble:before {
  content: "\e935";
}
.icon-facebook:before {
  content: "\e936";
}
.icon-github-circle:before {
  content: "\e937";
}
.icon-instagram:before {
  content: "\e938";
}
.icon-linkedin:before {
  content: "\e939";
}
.icon-pinterest:before {
  content: "\e93a";
}
.icon-pocket:before {
  content: "\e93b";
}
.icon-reddit:before {
  content: "\e93c";
}
.icon-rss:before {
  content: "\e93d";
}
.icon-telegram:before {
  content: "\e93e";
}
.icon-tumblr:before {
  content: "\e93f";
}
.icon-twitter:before {
  content: "\e940";
}
.icon-vk:before {
  content: "\e941";
}
