.pagination {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 45px;
}

.pagination-left,
.pagination-right {
    flex: 2;
    font-weight: 700;
}

.pagination-right {
    text-align: right;
}

.page-number {
    padding: 16px 16px;
    border: 1px solid var(--mid-gray-color);
    color: var(--secondary-text-color);
    font-size: 13px;
    line-height: 0;
    font-weight: 700;
    border-radius: 30px;
}
