.dimmer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 90;
    display: none;
    background: rgba(0, 0, 0, 0.7);
}
