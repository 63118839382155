.toggle-track {
    position: relative;
    display: flex;
    margin-left: 20px;
    width: 45px;
    height: 24px;
    user-select: none;
    background-color: var(--dark-gray-color);
    border-radius: 30px;
    cursor: pointer;
}

.toggle-sun,
.toggle-moon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1px;
    width: 50%;
    color: var(--white-color);
    font-size: 16px;
}

.toggle-moon {
    transform: translateX(1px);
}

.toggle-thumb {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background-color: var(--white-color);
    border-radius: 50%;
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.dark-mode .toggle-thumb {
    transform: translateX(21px);
}
