.dark-mode body {
    color: var(--dark-mode-primary-text-color);
    background-color: var(--dark-mode-bg-color);
}

.dark-mode a:not(.menu-item-link):not(.post-tag):not(.post-link),
.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6,
.dark-mode blockquote,
.dark-mode figcaption,
.dark-mode .u-text-format strong,
.dark-mode .logo-text,
.dark-mode .canvas-close,
.dark-mode .author-social-item:hover,
.dark-mode .error-code,
.dark-mode .menu-item-link:hover,
.dark-mode .kg-bookmark-title,
.dark-mode .kg-bookmark-author,
.dark-mode .kg-bookmark-publisher {
    color: var(--white-color);
}

.dark-mode .logo-image:not(.white) {
    opacity: 0;
}

.dark-mode .burger::before,
.dark-mode .burger::after,
.dark-mode hr,
.dark-mode hr::before,
.dark-mode hr::after {
    background-color: var(--white-color);
}

.dark-mode .logo-image.white {
    opacity: 1;
}

.dark-mode .menu-item-link,
.dark-mode .post-meta,
.dark-mode .post-link.secondary,
.dark-mode .page-number,
.dark-mode .author-location,
.dark-mode .author-social-item,
.dark-mode .button-arrow-icon,
.dark-mode .kg-bookmark-description {
    color: var(--dark-mode-secondary-text-color);
}

.dark-mode .post-footer,
.dark-mode .page-number,
.dark-mode .kg-bookmark-card {
    border-color: var(--dark-mode-border-color);
}

.dark-mode .comment-container,
.dark-mode .u-placeholder,
.dark-mode pre,
.dark-mode :not(pre) > code {
    background-color: var(--dark-mode-darker-bg-color);
}

.dark-mode .off-canvas {
    background-color: var(--dark-mode-bg-color);
}

.dark-mode .sep::after {
    background-color: var(--dark-mode-border-color);
}

.dark-mode .u-text-format ol li::before {
    font-weight: 700;
}

.dark-mode blockquote {
    border-color: var(--white-color);
}

.dark-mode .widget {
    color: var(--dark-mode-primary-text-color);
    background-color: var(--dark-mode-bg-color);
}

.dark-mode .widget input {
    color: var(--dark-mode-primary-text-color);
    background-color: var(--dark-mode-bg-color);
}
