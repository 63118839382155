blockquote table {
    border-spacing: 0;
    border-collapse: collapse;
}


blockquote table  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}


blockquote table  tr{
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
}


blockquote table tr:nth-child(2n) {
    background-color: #f6f8fa;
}


blockquote table td, blockquote table th {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
}

.dark-mode blockquote table td, .dark-mode blockquote table th {
    background-color: var(    --dark-mode-bg-color);
}
