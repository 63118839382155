.button-arrow-icon {
    display: inline-block;
    color: var(--secondary-text-color);
    vertical-align: middle;
    transition: transform 0.2s var(--animation-base);
}

.button-arrow-left .button-arrow-icon {
    margin-right: 3px;
    margin-left: -2px;
}

.button-arrow-right .button-arrow-icon {
    margin-right: -2px;
    margin-left: 3px;
}

.button-arrow-left:hover .button-arrow-icon {
    transform: translateX(-5px);
}

.button-arrow-right:hover .button-arrow-icon {
    transform: translateX(5px);
}
