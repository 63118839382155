.slicknav_menu .slicknav_nav {
  display: block !important;
  list-style-type: none;
  margin: 0 0 30px;
  padding: 0 20px;
  width: 100%;
}
.slicknav_menu .menu-item-link {
  display: block;
  outline: none;
  padding: 12px 0;
}
