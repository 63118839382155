.owl {
  display: none;
  position: relative;
  width: 100%;
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
}
.owl .owl-stage {
  position: relative;
  touch-action: pan-Y;
}
.owl .owl-stage:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
}
.owl .owl-stage-outer {
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0);
}
.owl .owl-item {
  backface-visibility: hidden;
  float: left;
  min-height: 1px;
  position: relative;
  transform: translateZ(0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl .owl-item > img {
  display: block;
  transform-style: preserve-3d;
  width: 100%;
}
.owl .owl-nav.disabled,
.owl .owl-dots.disabled {
  display: none;
}
.owl .owl-prev,
.owl .owl-next,
.owl .owl-dot {
  cursor: pointer;
  user-select: none;
}
.owl .owl-prev,
.owl .owl-next {
  background-color: var(--white-color);
  border: 0;
  border-radius: 50%;
  box-shadow: var(--shadow-base);
  color: var(--dark-gray-color);
  font-size: 21px;
  height: 40px;
  line-height: 40px;
  margin-top: -20px;
  opacity: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 130px;
  transition: color 0.5s var(--animation-base), opacity 0.5s var(--animation-base);
  width: 40px;
}
.owl .owl-prev.disabled,
.owl .owl-next.disabled {
  color: var(--secondary-text-color);
  cursor: default;
}
.owl .owl-prev {
  left: -20px;
}
.owl .owl-next {
  right: -20px;
}
.owl .owl-dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.owl .owl-dot {
  align-items: center;
  border: 0;
  display: flex;
  height: 20px;
  justify-content: center;
  outline: none;
  padding: 0;
  width: 20px;
}
.owl .owl-dot span {
  background-color: var(--mid-gray-color);
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
.owl .owl-dot.active span {
  background-color: var(--black-color);
}
.owl.owl-loaded {
  display: block;
}
.owl.owl-loading {
  display: block;
  opacity: 0;
}
.owl.owl-hidden {
  opacity: 0;
}
.owl.owl-refresh .owl-item {
  visibility: hidden;
}
.owl.owl-drag .owl-item {
  user-select: none;
}
.owl.owl-grab {
  cursor: move;
}
.no-js .owl {
  display: block;
}
.owl .animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
}
.owl .owl-animated-in {
  z-index: 0;
}
.owl .owl-animated-out {
  z-index: 1;
}
.owl .fadeOut {
  animation-name: fadeOut;
}
.owl-height {
  transition: height 500ms ease-in-out;
}
@media (max-width: 767px) {
  .owl .owl-prev {
    left: 10px;
  }
  .owl .owl-next {
    right: 10px;
  }
}
