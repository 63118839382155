:root {
    --primary-color: var(--ghost-accent-color, #49a8e4);
    --primary-text-color: #333;
    --secondary-text-color: #aaa;
    --red-color: #ff7473;
    --white-color: #fff;
    --light-gray-color: #f1f1f1;
    --mid-gray-color: #d9d9d9;
    --dark-gray-color: #1a1a1a;
    --black-color: #000;
    --dark-mode-bg-color: #282c35;
    --dark-mode-darker-bg-color: #21252c;
    --dark-mode-primary-text-color: #ccc;
    --dark-mode-secondary-text-color: #999;
    --dark-mode-border-color: #495061;
    --animation-base: ease-in-out;
    --shadow-base: 0 0 5px rgba(0, 0, 0, 0.2);
    --font-mono: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace; /* stylelint-disable-line value-keyword-case */
}

p {
    margin: 0 0 20px;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

blockquote {
    margin-right: 20px;
    margin-left: 20px;
    color: var(--dark-gray-color);
    font-size: 22px;
    line-height: 1.3;
    font-weight: 700;
}

blockquote strong {
    font-weight: 800;
}

blockquote img {
    display: inline;
}

ul,
ol {
    padding-left: 35px;
}

li {
    margin: 5px 0;
}

iframe,
embed {
    display: block;
    overflow: hidden;
    width: 100%;
    border: 0;
}

figure {
    margin-right: 0;
    margin-left: 0;
}

figcaption {
    margin-top: 15px;
    color: var(--dark-gray-color);
    font-size: 14px;
    text-align: center;
}

pre {
    overflow-x: scroll;
    padding: 16px 22px;
    line-height: 1.5;
    white-space: pre;
    hyphens: none;
    background-color: var(--light-gray-color);
    border-radius: 5px;
    -webkit-overflow-scrolling: touch;
}

code {
    font-family: var(--font-mono);
    font-size: 14px;
}

:not(pre) > code {
    padding: 3px 4px;
    background-color: var(--light-gray-color);
    border-radius: 3px;
}

hr {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    width: 5px;
    height: 5px;
    border: 0;
    background-color: var(--dark-gray-color);
    border-radius: 50%;
}

hr::before,
hr::after {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: var(--dark-gray-color);
    border-radius: 50%;
}

hr::before {
    right: 20px;
}

hr::after {
    left: 20px;
}
