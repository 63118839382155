.widget {
    padding: 20px;
    background-color: var(--white-color);
}

.widget-no-title {
    padding: 5px;
}

.widget-title {
    margin: -20px -20px 20px;
    padding: 15px 20px;
    border-bottom: 1px solid var(--light-gray-color);
    color: var(--secondary-text-color);
    font-family: var(--font-base);
    font-size: 11px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.widget-tags .tag + .tag {
    margin-top: 5px;
}

.widget-tags .tag-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.widget-tags .tag-name {
    font-family: var(--font-alt);
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}

.widget-tags .tag-count {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    height: 24px;
    color: var(--white-color);
    font-size: 12px;
    font-weight: 700;
    background-color: var(--primary-color);
    border-radius: 50%;
}

.widget-recent .recent {
    display: flex;
    align-items: center;
}

.widget-recent .recent + .recent {
    margin-top: 20px;
}

.widget-recent .post-header {
    overflow: hidden;
    margin-bottom: 0;
}

.widget-recent .post-title {
    overflow: hidden;
    font-size: 16px;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.widget-recent .post-date {
    margin-top: 3px;
}


/* search widget */

.widget-search {

}
.widget-search form {
    width: 100%;
    display:flex;
    flex-direction:row;
    border:1px solid var(--mid-gray-color);
    border-radius: 5px;
    padding:2px;
    position: relative;
}

.widget-search input{
    flex-grow:2;
    border:none;
}

.widget-search input::placeholder {
    color:var(--mid-gray-color);
}
.widget-search input:focus {
    outline: none;
}

.widget-search svg{
    /* Just a little styling to make it pretty */
    color:var(--mid-gray-color);
    padding: 2px;

}

#results {
    margin-top: 10px;
    padding: 10px;
    max-height: 300px;
    overflow: scroll;
    border:1px solid var(--mid-gray-color);
    border-radius: 5px;
    box-shadow: 0 4px 16px 0 var(--mid-gray-color);
}

#result .post-title {
    border-bottom: 1px solid var(--light-gray-color);
}

.results-hide{
    display: none;
}
/* end search widget */

@media (max-width: 767px) {
    .widget-title {
        margin-top: 0;
        margin-bottom: 0;
    }
}

#mc_embed_signup_scroll{
    display:flex;
    flex-direction:row;

    /* This bit draws the box around it */
    border:1px solid var(--mid-gray-color);
    border-radius: 5px;

    /* I've used padding so you can see the edges of the elements. */
    padding:2px;
}

#mc_embed_signup_scroll input {
    /* Tell the input to use all the available space */
    flex-grow:2;
    /* And hide the input's outline, so the form looks like the outline */
    border:none;

}

#mc_embed_signup_scroll input::placeholder {
    color:var(--mid-gray-color);
}

#mc_embed_signup_scroll input:focus {
    /* removing the input focus blue box. Put this on the form if you like. */
    outline: none;
}

#mc_embed_signup_scroll button {
    /* Just a little styling to make it pretty */
    border:1px solid var(--primary-color);
    background: var(--primary-color);
    color:var(--white-color);
    border-radius: 5px;

}
